import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
	routes: [{
			path: "/home",
			name: "home",
			component: () => import("../views/home/Home.vue"),
			meta: {
				keepAlive: true,
			},
		},
		{
			path: "/",
			name: "index",
			component: () => import("../views/index/Index.vue"),
			meta: {
				keepAlive: true,
			},
		},
		{
			path: "/test",
			name: "test",
			component: () => import("../views/test/test.vue"),
			meta: {
				keepAlive: true,
			},
		},
		{
			path: "/test2",
			name: "test2",
			component: () => import("../views/test/test2.vue"),
			meta: {
				keepAlive: true,
			},
		},
		{
			path: "/test3",
			name: "test3",
			component: () => import("../views/test/test3.vue"),
			meta: {
				keepAlive: true,
			},
		},
		{
			path: "/test4",
			name: "test4",
			component: () => import("../views/test/test4.vue"),
			meta: {
				keepAlive: true,
			},
		},
		{
			path: "/test5",
			name: "test5",
			component: () => import("../views/test/test5.vue"),
			meta: {
				keepAlive: true,
			},
		},
		{
			path: "/test_parallax",
			name: "test_parallax",
			component: () => import("../views/test/test_parallax.vue"),
			meta: {
				keepAlive: true,
			},
		},
	],
});
