<template>
	<div id="app">
	  <keep-alive>
	    <transition v-if="$route.meta.keepAlive" name="fade" mode="out-in">
	      <router-view></router-view>
	    </transition>
	  </keep-alive>
	
	  <transition name="fade" mode="out-in">
	    <router-view v-if="!$route.meta.keepAlive"></router-view>
	  </transition>
	  
	  <audio :src="bgSrc" loop hidden ref="music"></audio>
	</div>
</template>

<script>
	import { url_audio_bg } from '@/gdata/g_video.js'
	export default {
		name: 'App',
			data() {
				let _this = this
				return {
					bgSrc: url_audio_bg
				}
			},
			methods: {}
	}
</script>

<style lang="scss">
	@import url('./assets/font/font.scss');
	#app {
		width: 100%;
		height: 100%;
		background-color: #fff;
		color: #000;
		
		overflow: hidden;
		
		font-family: 'OpenSans-Regular';
	}
	
	html {
	  margin: 0;
	  padding: 0;
	  background-color: black;
	}
	
	body,
	html {
	  width: 100%;
	  height: 100%;
	  overflow: hidden;
	  background-color: #000;
	  
	  // cursor: url('./assets/images/mouse.jpg');
	  cursor: default;
	}
	
	.fade-enter-active,
	.fade-leave-active {
	  transition: opacity 1s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
	  opacity: 0;
	}
	
	/* fade */
	.pageTextFade08Effect {
		animation: pageTextFade08 2s linear forwards;
		-webkit-animation: pageTextFade08 2s linear forwards;
		-ms-animation: pageTextFade08 2s linear forwards;
		-moz-animation: pageTextFade08 2s linear forwards;
		
		animation-delay: 2s;
		-webkit-animation-delay: 2s;
		-ms-animation-delay: 2s;
		-moz-animation-delay: 2s;
	}
	
	@keyframes pageTextFade08 {
		0% {
			opacity: 0;
		}
	
		100% {
			opacity: 0.8;
		}
	}
	
	@-webkit-keyframes pageTextFade08 {
		0% {
			opacity: 0;
		}
	
		100% {
			opacity: 0.8;
		}
	}
	
	@-ms-keyframes pageTextFade08 {
		0% {
			opacity: 0;
		}
	
		100% {
			opacity: 0.8;
		}
	}
	
	@-moz-keyframes pageTextFade08 {
		0% {
			opacity: 0;
		}
	
		100% {
			opacity: 0.8;
		}
	}
	
	.fadeEffect {
	    animation: fading 1s linear;
	    -webkit-animation: fading 1s linear;
	    -ms-animation: fading 1s linear;
	    -moz-animation: fading 1s linear;
	}
	
	.fadeInEffect {
	    animation: fading 2s linear forwards;
	    -webkit-animation: fading 2s linear forwards;
	    -ms-animation: fading 2s linear forwards;
	    -moz-animation: fading 2s linear forwards;
	}
	
	.fadeIn4Effect {
	    animation: fading 4s linear forwards;
	    -webkit-animation: fading 4s linear forwards;
	    -ms-animation: fading 4s linear forwards;
	    -moz-animation: fading 4s linear forwards;
	}
	.fadeIn4Delay2SEffect {
	    animation: fading 4s linear forwards;
	    -webkit-animation: fading 4s linear forwards;
	    -ms-animation: fading 4s linear forwards;
	    -moz-animation: fading 4s linear forwards;
		
		animation-delay: 2s;
		-webkit-animation-delay: 2s;
		-ms-animation-delay: 2s;
		-moz-animation-delay: 2s;
	}
	
	.fadeIn1SEffect {
	    animation: fading 1s linear forwards;
	    -webkit-animation: fading 1s linear forwards;
	    -ms-animation: fading 1s linear forwards;
	    -moz-animation: fading 1s linear forwards;
	}
	
	.fadeIn2SDelay25SEffect {
	    animation: fading 2s linear forwards;
	    -webkit-animation: fading 2s linear forwards;
	    -ms-animation: fading 2s linear forwards;
	    -moz-animation: fading 2s linear forwards;
		
		animation-delay: 2.5s;
		-webkit-animation-delay: 2.5s;
		-ms-animation-delay: 2.5s;
		-moz-animation-delay: 2.5s;
	}
	
	.fadeIn1SDelay5SEffect {
	    animation: fading 1s linear forwards;
	    -webkit-animation: fading 1s linear forwards;
	    -ms-animation: fading 1s linear forwards;
	    -moz-animation: fading 1s linear forwards;
		
		animation-delay: 5s;
		-webkit-animation-delay: 5s;
		-ms-animation-delay: 5s;
		-moz-animation-delay: 5s;
	}
	
	.fadeIn1SDelay6SEffect {
	    animation: fading 1s linear forwards;
	    -webkit-animation: fading 1s linear forwards;
	    -ms-animation: fading 1s linear forwards;
	    -moz-animation: fading 1s linear forwards;
		
		animation-delay: 6s;
		-webkit-animation-delay: 6s;
		-ms-animation-delay: 6s;
		-moz-animation-delay: 6s;
	}
	
	.fadeIn1SDelay7SEffect {
	    animation: fading 1s linear forwards;
	    -webkit-animation: fading 1s linear forwards;
	    -ms-animation: fading 1s linear forwards;
	    -moz-animation: fading 1s linear forwards;
		
		animation-delay: 7s;
		-webkit-animation-delay: 7s;
		-ms-animation-delay: 7s;
		-moz-animation-delay: 7s;
	}
	
	.fadeIn1SDelay8SEffect {
	    animation: fading 1s linear forwards;
	    -webkit-animation: fading 1s linear forwards;
	    -ms-animation: fading 1s linear forwards;
	    -moz-animation: fading 1s linear forwards;
		
		animation-delay: 8s;
		-webkit-animation-delay: 8s;
		-ms-animation-delay: 8s;
		-moz-animation-delay: 8s;
	}
	
	.fadeIn1SDelay9SEffect {
	    animation: fading 1s linear forwards;
	    -webkit-animation: fading 1s linear forwards;
	    -ms-animation: fading 1s linear forwards;
	    -moz-animation: fading 1s linear forwards;
		
		animation-delay: 9s;
		-webkit-animation-delay: 9s;
		-ms-animation-delay: 9s;
		-moz-animation-delay: 9s;
	}
	
	.fadeIn1SDelay10SEffect {
	    animation: fading 1s linear forwards;
	    -webkit-animation: fading 1s linear forwards;
	    -ms-animation: fading 1s linear forwards;
	    -moz-animation: fading 1s linear forwards;
		
		animation-delay: 10s;
		-webkit-animation-delay: 10s;
		-ms-animation-delay: 10s;
		-moz-animation-delay: 10s;
	}
	
	.fadeIn1SDelay4SEffect {
	    animation: fading 1s linear forwards;
	    -webkit-animation: fading 1s linear forwards;
	    -ms-animation: fading 1s linear forwards;
	    -moz-animation: fading 1s linear forwards;
		
		animation-delay: 4s;
		-webkit-animation-delay: 4s;
		-ms-animation-delay: 4s;
		-moz-animation-delay: 4s;
	}
	
	.fadeIn1SDelay3SEffect {
	    animation: fading 1s linear forwards;
	    -webkit-animation: fading 1s linear forwards;
	    -ms-animation: fading 1s linear forwards;
	    -moz-animation: fading 1s linear forwards;
		
		animation-delay: 3s;
		-webkit-animation-delay: 3s;
		-ms-animation-delay: 3s;
		-moz-animation-delay: 3s;
	}
	
	.fadeIn1SDelay2SEffect {
	    animation: fading 1s linear forwards;
	    -webkit-animation: fading 1s linear forwards;
	    -ms-animation: fading 1s linear forwards;
	    -moz-animation: fading 1s linear forwards;
		
		animation-delay: 2s;
		-webkit-animation-delay: 2s;
		-ms-animation-delay: 2s;
		-moz-animation-delay: 2s;
	}
	
	.fadeIn1SDelay1SEffect {
	    animation: fading 1s linear forwards;
	    -webkit-animation: fading 1s linear forwards;
	    -ms-animation: fading 1s linear forwards;
	    -moz-animation: fading 1s linear forwards;
		
		animation-delay: 1s;
		-webkit-animation-delay: 1s;
		-ms-animation-delay: 1s;
		-moz-animation-delay: 1s;
	}
	
	.fadeIn1SDelay2SEffect {
	    animation: fading 1s linear forwards;
	    -webkit-animation: fading 1s linear forwards;
	    -ms-animation: fading 1s linear forwards;
	    -moz-animation: fading 1s linear forwards;
		
		animation-delay: 2s;
		-webkit-animation-delay: 2s;
		-ms-animation-delay: 2s;
		-moz-animation-delay: 2s;
	}
	
	@keyframes fading {
	    0% {
	        opacity: 0;
	    }
	
	    100% {
	        opacity: 1.0;
	    }
	}
	
	@-webkit-keyframes fading {
	    0% {
	        opacity: 0;
	    }
	
	    100% {
	        opacity: 1.0;
	    }
	}
	
	@-ms-keyframes fading {
	    0% {
	        opacity: 0;
	    }
	
	    100% {
	        opacity: 1.0;
	    }
	}
	
	@-moz-keyframes fading {
	    0% {
	        opacity: 0;
	    }
	
	    100% {
	        opacity: 1.0;
	    }
	}
	
	/* fade out */
	.fadeOutEffect {
	    animation: fadingOut .5s linear forwards;
	    -webkit-animation: fadingOut .5s linear forwards;
	    -ms-animation: fadingOut .5s linear forwards;
	    -moz-animation: fadingOut .5s linear forwards;
	}
	
	@keyframes fadingOut {
	    0% {
	        opacity: 1;
	    }
	
	    100% {
	        opacity: 0;
	    }
	}
	
	@-webkit-keyframes fadingOut {
	    0% {
	        opacity: 1;
	    }
	
	    100% {
	        opacity: 0;
	    }
	}
	
	@-ms-keyframes fadingOut {
	    0% {
	        opacity: 1;
	    }
	
	    100% {
	        opacity: 0;
	    }
	}
	
	@-moz-keyframes fadingOut {
	    0% {
	        opacity: 1;
	    }
	
	    100% {
	        opacity: 0;
	    }
	}
	
	/* breathing */
	.breathingEffect1 {
	    animation: breathing 3s ease-in infinite;
	    -webkit-animation: breathing 3s ease-in infinite;
	    -ms-animation: breathing 3s ease-in infinite;
	    -moz-animation: breathing 3s ease-in infinite;
	}
	
	.breathingEffect2 {
	    animation: breathing 4s ease-out infinite;
	    -webkit-animation: breathing 4s ease-out infinite;
	    -ms-animation: breathing 4s ease-out infinite;
	    -moz-animation: breathing 4s ease-out infinite;
	}
	
	@keyframes breathing {
	    0% {
	        opacity: 0;
	    }
	
	    100% {
	        opacity: 1.0;
	    }
	}
	
	@-webkit-keyframes breathing {
	    0% {
	        opacity: 0;
	    }
	
	    100% {
	        opacity: 1.0;
	    }
	}
	
	@-ms-keyframes breathing {
	    0% {
	        opacity: 0;
	    }
	
	    100% {
	        opacity: 1.0;
	    }
	}
	
	@-moz-keyframes breathing {
	    0% {
	        opacity: 0;
	    }
	
	    100% {
	        opacity: 1.0;
	    }
	}
	
	/* fade */
	.rotateEffect {
	    animation: rotating 15s linear infinite;
	    -webkit-animation: rotating 15s linear infinite;
	    -ms-animation: rotating 15s linear infinite;
	    -moz-animation: rotating 15s linear infinite;
	}
	
	@keyframes rotating {
	    0% {
	        transform: rotate(0deg);
	    }
	
	    100% {
	        transform: rotate(360deg);
	    }
	}
	
	@-webkit-keyframes rotating {
	    0% {
	        transform: rotate(0deg);
	    }
	    	
	    100% {
	        transform: rotate(360deg);
	    }
	}
	
	@-ms-keyframes rotating {
	    0% {
	        transform: rotate(0deg);
	    }
	    	
	    100% {
	        transform: rotate(360deg);
	    }
	}
	
	@-moz-keyframes rotating {
	    0% {
	        transform: rotate(0deg);
	    }
	    	
	    100% {
	        transform: rotate(360deg);
	    }
	}
	
	/* moveToLeft */
	.moveToLeft1SEffect {
	    animation: moveToLeftScreen 1s ease-in-out;
	    -webkit-animation: moveToLeftScreen 1s ease-in-out;
	    -ms-animation: moveToLeftScreen 1s ease-in-out;
	    -moz-animation: moveToLeftScreen 1s ease-in-out;
	}
	
	.moveToLeft15SEffect {
	    animation: moveToLeftScreen 1.5s ease-in-out;
	    -webkit-animation: moveToLeftScreen 1.5s ease-in-out;
	    -ms-animation: moveToLeftScreen 1.5s ease-in-out;
	    -moz-animation: moveToLeftScreen 1.5s ease-in-out;
	}
	.moveToLeft2SEffect {
	    animation: moveToLeftScreen 2s ease-in-out;
	    -webkit-animation: moveToLeftScreen 2s ease-in-out;
	    -ms-animation: moveToLeftScreen 2s ease-in-out;
	    -moz-animation: moveToLeftScreen 2s ease-in-out;
	}
	
	@keyframes moveToLeftScreen {
	    0% {
	        transform: translateX(0);
	    }
	
	    100% {
	        transform: translateX(-100%);
	    }
	}
	
	@-webkit-keyframes moveToLeftScreen {
	    0% {
	        transform: translateX(0);
	    }
	
	    100% {
	        transform: translateX(-100%);
	    }
	}
	
	@-ms-keyframes moveToLeftScreen {
	    0% {
	        transform: translateX(0);
	    }
	
	    100% {
	        transform: translateX(-100%);
	    }
	}
	
	@-moz-keyframes moveToLeftScreen {
	    0% {
	        transform: translateX(0);
	    }
	
	    100% {
	        transform: translateX(-100%);
	    }
	}
</style>
