import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        postData: {
            source :'',  // 经销商
            full_name: '', //姓名
            mobile: '', //手机
            title: '',  // 参展日期
            country: 'CN', //语言
            group: '',  //参展类型
            card_num: '', //身份证号码
            needCard: false,
            registerFriend: false,
            friendName: ''  // 朋友名字
        },
        imgUrl: ''  // 身份证地址
    },
    mutations: {
        createPostData(state,postData) {
            state.postData = postData ;
        },
        createImgUrl (state,imgUrl) {
            state.imgUrl = imgUrl
        }
    },
    actions: {}
})
