// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import axios from "axios";
import "lib-flexible/flexible";
import store from "./store";
import animated from "animate.css";
import vueSeamlessScroll from "vue-seamless-scroll"

Vue.use(animated);
Vue.use(vueSeamlessScroll)

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
